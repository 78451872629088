import { api } from '@/src/shared/lib/api'
import { Gender } from '@/src/shared/types'

interface ApiUserPostPayload {
  name?: string
  age?: number
  gender?: Gender
  approvedTerms?: boolean
  approvedVideochatRules?: boolean
}

export async function userPost<ApiResponse>(payload: ApiUserPostPayload) {
  const url = api.appURL('user')
  const formData = new FormData()
  Object.keys(payload).forEach((name: string) => {
    switch (name) {
      case 'name':
        if (payload.name) formData.append(name, payload.name)

        break
      case 'age':
        if (payload.age) formData.append(name, payload.age.toString())
        break
      case 'gender':
        switch (payload.gender) {
          case 'male':
            formData.append(name, '1')
            break
          case 'female':
            formData.append(name, '2')
            break
        }
        break
      case 'approvedTerms':
        formData.append(name, 'true')
        break
      case 'approvedVideochatRules':
        formData.append(name, 'true')
        break
    }
  })
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
