import { Plugin, Context } from '@nuxt/types'
import { AmplitudeService } from './amplitude.service'
import { cookiesAccess } from '@/src/shared/lib/cookiesAccess'

const nuxtPlugin: Plugin = (context: Context, inject: Function) => {
  const amplitudeService = new AmplitudeService(context)
  inject('amplitude', amplitudeService)

  const access = cookiesAccess.canInit('analytics')
  if (!access) return

  amplitudeService.init()
}

export default nuxtPlugin
