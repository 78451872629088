import axios, { AxiosResponse } from 'axios'
import { ApiRequestConfig, ApiUrl, ApiResponseBase } from './types'
import { ApiError, APIErrorDescription } from './api.error'
import { $axios } from './axios.service'
import { IError } from '@/src/shared/lib/error'

export async function axiosRequest<R>(
  config: ApiRequestConfig
): Promise<AxiosResponse<R & ApiResponseBase> | IError> {
  try {
    const response: AxiosResponse<R & ApiResponseBase> = await $axios(config)
    return response
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.status) {
        return new ApiError(
          APIErrorDescription.axios,
          err.response?.status,
          err.response?.data
        )
      } else {
        return new ApiError(APIErrorDescription.axios, 0, err.message)
      }
    }
    return new ApiError(APIErrorDescription.unknown, 0)
  }
}

export async function axiosGet<R>(
  url: ApiUrl,
  config: ApiRequestConfig = {}
): Promise<AxiosResponse<R & ApiResponseBase> | IError> {
  return await axiosRequest<R>({
    method: 'GET',
    url: url.href,
    ...config,
  })
}

export async function axiosPost<R>(
  url: ApiUrl,
  data: any,
  config: ApiRequestConfig = {}
): Promise<AxiosResponse<R & ApiResponseBase> | IError> {
  return await axiosRequest<R>({
    method: 'POST',
    url: url.href,
    data,
    ...config,
  })
}

export async function axiosPut<R>(
  url: ApiUrl,
  data: any,
  config: ApiRequestConfig = {}
): Promise<AxiosResponse<R & ApiResponseBase> | IError> {
  return await axiosRequest<R>({
    method: 'PUT',
    url: url.href,
    data,
    ...config,
  })
}

export async function axiosDelete<R>(
  url: ApiUrl,
  config: ApiRequestConfig = {}
): Promise<AxiosResponse<R & ApiResponseBase> | IError> {
  return await axiosRequest<R>({
    method: 'DELETE',
    url: url.href,
    ...config,
  })
}
