export * from './env'
export * from './routeNav'
export * from './translate'
export * from './modal'
export * from './emails'

export const ROUTE_NAV2 = {
  authGender: '/auth/funnel2/gender/',
  main: '/cab2/main/',
  randomCall: '/cab2/random-call/',
  privateCall: '/cab2/private-call/',
  messages: '/cab2/messages/',
  stories: '/cab2/stories/',
  invoiceHistory: '/cab2/invoice-history/',
  blocklist: '/cab2/blocklist/',
  topProfiles: '/cab2/top-profiles/',
}
