import { api } from '@/src/shared/lib/api'

export async function paymentSolidGateGetPaymentPost<ApiResponse>(
  productId: string
) {
  const url = api.appURL('payment/solid_gate/get_payment')

  url.searchParams.append('google_pay', '1')
  url.searchParams.append('apple_pay', '1')

  const formData = {
    product_id: productId,
    context: {
      text: 'view1',
    },
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
