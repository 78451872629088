import { api } from '@/src/shared/lib/api'

export async function paymentSolidGateOrderStatusPost<ApiResponse>(
  orderId: number
) {
  const url = api.appURL('payment/solid_gate/order_status')
  const formData = {
    order_id: orderId.toString(),
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
