import Vue from 'vue'
import type { Plugin, Context } from '@nuxt/types'

import type { IToasterPlugin } from '../types'
import { ToasterPluginDesktop, ToasterPluginMobile } from '../ui'
import { ToasterDesktop, ToasterMobile } from './toaster.api'

const toasterPlugin: Plugin = (_: Context, inject: Function) => {
  const isDesktop = window.innerWidth >= 1280

  const Toaster = isDesktop ? ToasterDesktop : ToasterMobile

  const toasterPluginComponent = isDesktop
    ? ToasterPluginDesktop
    : ToasterPluginMobile

  const ToasterPublic: IToasterPlugin = {
    info: Toaster.info,
    error: Toaster.error,
  }

  Object.setPrototypeOf(ToasterPublic, Toaster)

  Vue.component('ToasterList', toasterPluginComponent)
  inject('toaster', ToasterPublic)
}

export default toasterPlugin
