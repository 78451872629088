// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BUmk[data-v-5c9e74d6]{padding:0 16px;color:#fff;background-color:#222020}.BUmk[data-v-5c9e74d6]:hover{background-color:#1a2335}.BUmk[data-v-5c9e74d6]:active{background-color:#000}.BUmk[disabled][data-v-5c9e74d6]:active,.BUmk[disabled][data-v-5c9e74d6]:hover{background-color:#e8ebf3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "BUmk"
};
module.exports = ___CSS_LOADER_EXPORT___;
