import { Context, Plugin } from '@nuxt/types'
import { GoogleAnalyticsService } from './googleAnalytics.service'
import { cookiesAccess } from '@/src/shared/lib/cookiesAccess'

/**
 * @link https://github.com/nuxt-community/google-gtag-module/blob/master/lib/plugin.js
 */
const nuxtPlugin: Plugin = (context: Context, inject: Function) => {
  const googleAnalyticsService = new GoogleAnalyticsService(context)
  inject('ga', googleAnalyticsService)

  const access = cookiesAccess.canInit('analytics')
  if (!access) return
  googleAnalyticsService.init()
}

export default nuxtPlugin
