import { AppWarmup } from '../types'
import { useAppStore } from './app.store'
import { webappWarmupGet } from '@/src/shared/api'

type AppWarmupApiResponse = AppWarmup

export async function warmupLoad() {
  const appStore = useAppStore()

  const response = await webappWarmupGet<AppWarmupApiResponse>()
  // TODO Validation
  if (response.isOk) {
    appStore.warmup.nodeUrl = response.data?.nodeUrl
    appStore.warmup.webRtc = response.data?.webRtc
    appStore.warmup.uploadPhotoPk = response.data?.uploadPhotoPk
    appStore.warmup.uploadPhotoUrl = response.data?.uploadPhotoUrl
  }
}
