const pkg = require('../../../package.json')

export const env = Object.freeze({
  PROJECT_NAME: pkg.name,
  VERSION: pkg.version || 0,
  NODE_ENV: process.env.NODE_ENV,
  IS_DEV: process.env.NODE_ENV === 'development',
  IS_STAGE: process.env.NODE_ENV === 'stage',
  IS_PROD: process.env.NODE_ENV === 'production',
  AUTH_URL: process.env.AUTH_URL,
  APP_URL: process.env.APP_URL,
  TRACK_URL: process.env.TRACK_URL,
  SOCKET_URL: process.env.SOCKET_URL || '',
  APPLICATION: process.env.APPLICATION,
  VERSION_BACKEND: process.env.VERSION_BACKEND || '0',
  WEBSOCKET_APPLICATION: process.env.WEBSOCKET_APPLICATION || '',
  UPLOAD_APP: process.env.UPLOAD_APP,
  UPLOAD_APP_NAME: process.env.UPLOAD_APP_NAME,

  GOOGLE_TAG_MANAGER_ID: process.env.GOOGLE_TAG_MANAGER_ID,
  GOOGLE_MEASUREMENT_ID: process.env.GOOGLE_MEASUREMENT_ID,
  GOOGLE_ANALYTICS_ID: process.env.GOOGLE_ANALYTICS_ID,
  GOOGLE_OPTIMIZE_ID: process.env.GOOGLE_OPTIMIZE_ID,
  GOOGLE_OAUTH_CLIENT_ID: process.env.GOOGLE_OAUTH_CLIENT_ID,
  GOOGLE_TRANSLATE_KEY: process.env.GOOGLE_TRANSLATE_KEY,

  SENTRY_DNS: process.env.SENTRY_DNS || '',
  SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN || '',
  SENTRY_ORG: process.env.SENTRY_ORG || '',
  SENTRY_PROJECT: pkg.name,

  FACEBOOK_PIXEL_ID: process.env.FACEBOOK_PIXEL_ID,
  FACEBOOK_TEST_CODE: process.env.FACEBOOK_TEST_CODE,

  AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
  GMEM_AMPLITUDE_API_KEY: process.env.GMEM_AMPLITUDE_API_KEY,

  HOTJAR_ID: process.env.HOTJAR_ID,
  HOTJAR_VERSION: process.env.HOTJAR_VERSION,

  FIREBASE_API_KEY: process.env.FIREBASE_API_KEY || '',
  FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN || '',
  FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID || '',
  FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET || '',
  FIREBASE_MESSAGE_SENDER_ID: process.env.FIREBASE_MESSAGE_SENDER_ID || '',
  FIREBASE_APP_ID: process.env.FIREBASE_APP_ID || '',
  FIREBASE_MESSAGE_VIPED_KEY: process.env.FIREBASE_MESSAGE_VIPED_KEY || '',
  FIREBASE_MEASUREMENT_ID: process.env.FIREBASE_MEASUREMENT_ID || '',
})
