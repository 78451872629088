import { AxiosRequestConfig } from 'axios'
import { Plugin } from '@nuxt/types'
import { axiosSet } from './axios.service'
import { deviceIdGet } from '@/src/shared/lib/deviceId'
// import { env } from '@/src/shared/config'
import { languageBrowser } from '@/logic/helpers'

const accessor: Plugin = ({ $axios }) => {
  $axios.defaults.headers['X-Application'] = process.env.APPLICATION
  $axios.defaults.headers['X-Version-Backend'] = process.env.VERSION_BACKEND
  $axios.onRequest((config: AxiosRequestConfig) => {
    config.headers['X-Device-Id'] = deviceIdGet()
    config.headers.Locale = languageBrowser()
  })
  axiosSet($axios)
}

export default accessor
