import { ApiUrl } from './types'
import { env } from '@/src/shared/config'

export function authURL(path: string): ApiUrl {
  const host = env.AUTH_URL as string
  return new URL(`${host}/${path}`)
}

export function appURL(path: string): ApiUrl {
  const host = env.APP_URL as string
  return new URL(`${host}/${path}`)
}

export function trackURL(path: string): ApiUrl {
  const host = env.TRACK_URL as string
  return new URL(`${host}/${path}`)
}

export function uploadURL(path: string): ApiUrl {
  const host = ''
  return new URL(`${host}/${path}`)
}
