import { Plugin, Context } from '@nuxt/types'
import { eventBus } from '@/src/shared/lib/eventBus'
import { ModalService } from '@/src/shared/lib/modal'

const customPlugin: Plugin = (_: Context, inject: Function) => {
  const modalService = new ModalService(eventBus)
  inject('modalOld', modalService)
}

export default customPlugin
