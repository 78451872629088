import { api } from '@/src/shared/lib/api'

export async function preRegistrationFinishPost<ApiResponse>(
  email: string,
  password?: string
) {
  const url = api.authURL('pre_registration/finish')
  const formData = {
    email,
    password,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
