import { api } from '@/src/shared/lib/api'

export async function paymentRefundPost<ApiResponse>(
  transactionId: number,
  description: string
) {
  const url = api.appURL('payment/refund')
  const formData = {
    transactionId,
    description,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
