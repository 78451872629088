// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZHzm[data-v-2f53f8d7]{padding:0 16px;color:#fff;background-color:#ff3d3d}.ZHzm[data-v-2f53f8d7]:active{background-color:linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.3)),#ff3d3d}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "ZHzm"
};
module.exports = ___CSS_LOADER_EXPORT___;
