import { api } from '@/src/shared/lib/api'

export async function userFavoritesPost<ApiResponse>(
  userId: number,
  context: number
) {
  const url = api.appURL('user/favorites')
  const formData = {
    userId,
    context,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
