let disabledSend = false

export function addScript(src: string) {
  const script = document.createElement('script')
  script.async = true
  script.src = src
  document.head.appendChild(script)
}

export function init() {
  window.dataLayer = window.dataLayer || []
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out?hl=en
export function disableTrack(disabled: boolean) {
  disabledSend = disabled
}

// https://developers.google.com/tag-platform/gtagjs/reference
const gtag: Function = function gtag() {
  if (!window.dataLayer || disabledSend) return
  window.dataLayer.push(arguments)
}

export { gtag }
