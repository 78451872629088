import { api } from '@/src/shared/lib/api'

export async function utilsFeedbackPost<ApiResponse>(
  topic: string,
  text: string
) {
  const url = api.appURL('utils/feedback')
  const formData = new FormData()
  formData.append('topic', topic)
  formData.append('text', text)
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
