import { api } from '@/src/shared/lib/api'

export async function paymentSolidGateAutoTopUpPost<ApiResponse>(
  enabled: boolean
) {
  const url = api.appURL('payment/solid_gate/auto_top_up')
  const formData = {
    enabled,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
