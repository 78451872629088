import { api } from '@/src/shared/lib/api'

type VideoCallStatPostPayload = {
  peerUserId: number
  duration: number
  callId: string
  skipType: string
  skipReason: string
  skipDetails?: string
}

export async function videoCallStatPost<ApiResponse>(
  payload: VideoCallStatPostPayload
) {
  const url = api.appURL('video-call-stat')
  const response = await api.post<ApiResponse>(url, payload)
  return api.parseRespone<ApiResponse>(response)
}
