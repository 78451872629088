// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Malf[data-v-438a14cc]{display:flex;flex-direction:row;justify-content:center;align-items:center;width:auto;border:none;border-radius:12px;cursor:pointer;outline:none;-webkit-user-select:none;-moz-user-select:none;user-select:none;transition:.25s;font-family:\"Poppins\",sans-serif;font-size:15px;font-style:normal;font-weight:500;line-height:24px;letter-spacing:-.2px}.Malf[data-v-438a14cc]:hover{box-shadow:0 5px 14px rgba(0,0,0,.26)}.Malf[data-v-438a14cc]:active{box-shadow:none}.Malf[disabled][data-v-438a14cc]{color:#6f7580;cursor:not-allowed;background-color:#e8ebf3}.Malf[disabled][data-v-438a14cc]:hover{box-shadow:none}.Malf[disabled][data-v-438a14cc]:active{box-shadow:none;background-color:#e8ebf3}.Malf.v6Wx[data-v-438a14cc]{height:32px;border-radius:8px}.Malf.BxDG[data-v-438a14cc]{height:44px}.Malf.Q2aw[data-v-438a14cc]{height:50px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "Malf",
	"comp__32": "v6Wx",
	"comp__44": "BxDG",
	"comp__50": "Q2aw"
};
module.exports = ___CSS_LOADER_EXPORT___;
