import { api } from '@/src/shared/lib/api'

export async function giftSubmitPost<ApiResponse>(
  userId: number,
  giftId: number,
  entry: string
) {
  const url = api.appURL('gift/submit')
  const formData = {
    toUserId: typeof userId === 'string' ? parseInt(userId) : userId,
    giftId,
    context: {
      channel: entry,
    },
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
