// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+nbL[data-v-5fb52de0]{padding:0 16px;color:#fff;background-color:#3a86ff}.\\+nbL[data-v-5fb52de0]:active{background-color:linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.3)),#3a86ff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "+nbL"
};
module.exports = ___CSS_LOADER_EXPORT___;
