import { ref } from '@nuxtjs/composition-api'
import { IAnalyticsService } from './analytics.types'

const analyticsServiceCache = ref<IAnalyticsService>()

export function analyticsService(): IAnalyticsService {
  if (!analyticsServiceCache.value) throw new Error('AnalyticsEmpty')
  return analyticsServiceCache.value
}

export function analyticsServiceSet(service: IAnalyticsService) {
  analyticsServiceCache.value = service
}
