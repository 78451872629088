import { Context } from '@nuxt/types'
import {
  IAnalyticsService,
  AnalyticAuthMethod,
  IAnalyticUserProps,
  IAnalyticEventData,
  IAnalyticEventOptions,
  IAnalyticGAPurchase,
  IAnalyticGAPurchaseItem,
} from './analytics.types'
import { env } from '@/src/shared/config'
import { GtagGAUserPropsSet } from '@/src/shared/lib/gtag'
import { AmplitudeUserPropsSet } from '@/src/shared/lib/amplitude'

export class AnalyticsService implements IAnalyticsService {
  protected _context: Context
  protected _windowId: string
  protected _windowAmplitudeSeq: number = 0
  protected _windowGoogleSeq: number = 0
  protected _isLog: boolean

  constructor(context: Context, windowId: string) {
    this._context = context
    this._windowId = windowId
    this._isLog = env.IS_DEV || env.IS_STAGE
  }

  public windowId(): string {
    return this._windowId
  }

  public login(method: AnalyticAuthMethod): void {
    this._context.$ga.event('login', { method })
    this._context.$amplitude.userPropsSet({
      login_source: method,
    })
    // hotjar.event('login')
  }

  public signUp(method: AnalyticAuthMethod): void {
    this._context.$ga.event('sign_up', { method })
    this._context.$amplitude.userPropsSet({
      reg_source: method,
    })
    // hotjar.event('sign_up')
  }

  public userId(userId: string): void {
    if (this._isLog) console.log('AnalyticsService: userId = %s', userId)
    this._context.$ga.userIdSet(userId)
    // this._context.$ga.userPropsSet({ userId })
    this._context.$amplitude.userIdSet(userId)
    // hotjar.userIdSet(userId)
    this._context.$sentry.setUser({
      id: userId,
    })
  }

  public userPropsGet(
    props: Partial<IAnalyticUserProps> = {}
  ): IAnalyticUserProps {
    return {
      ...props,
    }
  }

  public userProps(props: IAnalyticUserProps): void {
    if (this._isLog) {
      console.group('AnalyticsService: userProp')
      Object.keys(props).forEach((name: string) =>
        console.log('%s: %s', name, props[name as keyof IAnalyticUserProps])
      )
      console.groupEnd()
    }

    this._context.$amplitude.userPropsSet(props as AmplitudeUserPropsSet)
    this._context.$ga.userPropsSet(props as GtagGAUserPropsSet)
    // TODO
    // if (Date.now() === 0) hotjar.userPropsSet(props as HotjarUserPropsSet)
  }

  public event(
    event: string,
    data?: IAnalyticEventData,
    options?: IAnalyticEventOptions
  ): void {
    if (this._isLog) console.group('AnalyticsService: ' + event)

    if (!options?.amplitudeDisabled) {
      const amplitudeData = Object.assign({}, data, {
        window: this._windowId,
        windowSeq: this._windowAmplitudeSeq++,
      })
      if (this._isLog) {
        console.log('Amplitude event: %s', event, amplitudeData)
      }
      this._context.$amplitude.event(event, amplitudeData)
    }

    if (!options?.gaDisabled) {
      const googleData = Object.assign({}, data, {
        window: this._windowId,
        windowSeq: this._windowGoogleSeq++,
      })
      if (this._isLog) {
        console.log(
          'GoogleAnalytics event: %s',
          event,
          googleData,
          'color: orange;'
        )
      }
      this._context.$ga.event(event, googleData)
    }
    if (this._isLog) console.groupEnd()
  }

  /**
   * @link https://developers.google.com/gtagjs/reference/ga4-events#purchase
   * @param {IAnalyticGAPurchase} props
   * @param {IAnalyticGAPurchaseItem[]} items
   */
  public gaPurchase(
    props: IAnalyticGAPurchase,
    items: IAnalyticGAPurchaseItem[]
  ): void {
    if (this._isLog) console.log('GoogleAnalytics purchase: ', props, items)
    this._context.$ga.gaPurchase(props, items)
  }

  public amplitudeRevenue(price: number): void {
    if (this._isLog) console.log('Amplitude revenue: ', price)
    this._context.$amplitude.revenueSet(price)
  }
}
