import { Plugin, Context } from '@nuxt/types'
import { AnalyticsService } from './analytics.service'
import * as analyticsFactory from './analytics.factory'
import { windowIdGet } from '@/src/shared/lib/windowId'
import { env } from '@/src/shared/config'

const analyticsPlugin: Plugin = (context: Context, inject: Function) => {
  const analyticsService = new AnalyticsService(context, windowIdGet())
  analyticsFactory.analyticsServiceSet(analyticsService)
  inject('analytics', analyticsService)

  analyticsService.userProps({
    domain: 'peachfy',
  })

  const onPopstate = () => {
    analyticsService.event('back_button_pressed', { type: 'back_next' })
  }
  const onBeforeunload = () => {
    analyticsService.event('back_button_pressed', { type: 'unload' })
  }
  window.addEventListener('popstate', onPopstate, false)
  window.addEventListener('beforeunload', onBeforeunload, false)
}

export default analyticsPlugin
