import { ApiResponseBase } from './types'
import { IError, ErrorBase } from '@/src/shared/lib/error'

const ERROR_NAME = 'API'

export enum APIErrorDescription {
  'unknown' = 'unknown',
  'axios' = 'axios',
}

export class ApiError<R> extends ErrorBase implements IError {
  constructor(
    description: APIErrorDescription,
    public readonly codeStatus: number,
    public readonly data?: R & ApiResponseBase
  ) {
    super(ERROR_NAME, description)
  }
}
