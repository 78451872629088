import type { AxiosRequestConfig } from 'axios'
import { api } from '@/src/shared/lib/api'

export async function threadAttachmentImagePost<ApiResponse>(
  file: File | Blob,
  config: AxiosRequestConfig
) {
  const url = api.appURL('thread/attachment/image')
  const formData = new FormData()
  formData.append('file[file]', file)
  const response = await api.post<ApiResponse>(url, formData, config)
  return api.parseRespone<ApiResponse>(response)
}
