import { api } from '@/src/shared/lib/api'

export async function paypalOrderInitPost<ApiResponse>(bundleKey: string) {
  const url = api.appURL('paypal/order/init')
  const formData = {
    bundleKey,
  }
  const response = await api.post<ApiResponse>(url, formData, {})
  return api.parseRespone<ApiResponse>(response)
}
