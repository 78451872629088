import { api } from '@/src/shared/lib/api'

export async function videoAnswerPost<ApiResponse>(
  callerId: number,
  receiverId: number,
  answer: boolean
) {
  const url = api.appURL('video/answer')
  const formData = {
    callerId,
    receiverId,
    answer,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
