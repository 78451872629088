import { api } from '@/src/shared/lib/api'

export async function videoProfilesCategoryGet<ApiResponse>(
  category?: number,
  expludeIds?: number[],
  limit?: number
) {
  const url = api.appURL('video/profiles/category')
  if (category !== undefined) {
    url.searchParams.append('category', String(category))
  }
  if (expludeIds && expludeIds.length) {
    url.searchParams.append('excludeIds', expludeIds.join('/'))
  }
  if (limit !== undefined) {
    url.searchParams.append('limit', String(limit))
  }
  const response = await api.get<ApiResponse>(url)
  return api.parseRespone<ApiResponse>(response)
}
