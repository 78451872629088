import { computed } from '@nuxtjs/composition-api'
import { useAppStore } from './app.store'
import { appLoading } from '@/logic/app'

export const loader = computed(() => {
  const appStore = useAppStore()
  return appLoading.value && appStore.loader
})

export function loaderOn() {
  const appStore = useAppStore()
  appLoading.value = true
  appStore.loader = true
}

export function loaderOff() {
  const appStore = useAppStore()
  appLoading.value = false
  appStore.loader = false
}
