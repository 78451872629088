const CACHE_NAME = 'cookies-access'
const NEED_SHOW_NAME = 'cookies-access-needshow'

type CookiesAccessCache = {
  functional: boolean
  analytics: boolean
  perfomance: boolean
}

type CookiesAccessKey = keyof CookiesAccessCache

export interface ICookiesAccessService {
  cache: CookiesAccessCache | null
  isEmpty: boolean
  needShow: boolean | null
  initShow(): void
  canInit(name: CookiesAccessKey): boolean
  checkAccess(name: CookiesAccessKey): boolean
  acceptAll(): void
  rejectAll(): void
  custom(functional: boolean, analytics: boolean, perfomance: boolean): void
}

class CookiesAccessService implements ICookiesAccessService {
  private generate(): CookiesAccessCache {
    return {
      functional: true,
      analytics: true,
      perfomance: true,
    }
  }

  get cache(): CookiesAccessCache | null {
    const cache = sessionStorage.getItem(CACHE_NAME)
    return cache ? JSON.parse(cache) : cache
  }

  set cache(cache: CookiesAccessCache | null) {
    if (cache) {
      sessionStorage.setItem(CACHE_NAME, JSON.stringify(cache))
    } else {
      sessionStorage.removeItem(CACHE_NAME)
    }
  }

  get isEmpty(): boolean {
    return this.cache === null
  }

  get needShow(): boolean | null {
    const needShow = sessionStorage.getItem(NEED_SHOW_NAME)
    return needShow ? needShow === 'true' : null
  }

  set needShow(value: boolean | null) {
    if (value !== null) {
      sessionStorage.setItem(NEED_SHOW_NAME, value.toString())
    } else {
      sessionStorage.removeItem(NEED_SHOW_NAME)
    }
  }

  public initShow(): void {
    const url = new URL(location.href)
    if (url.pathname !== '/') return
    if (this.needShow === false) return
    if (this.cache !== null) return
    this.needShow = true
  }

  public canInit(name: CookiesAccessKey): boolean {
    if (this.needShow) return false
    if (this.cache && !this.cache[name]) return false
    return true
  }

  public checkAccess(name: CookiesAccessKey): boolean {
    if (!this.cache) return true
    return this.cache[name]
  }

  public acceptAll(): void {
    this.cache = this.generate()
    this.needShow = false
  }

  public rejectAll(): void {
    const cookiesAccess = this.generate()
    cookiesAccess.functional = false
    cookiesAccess.analytics = false
    cookiesAccess.perfomance = false
    this.cache = cookiesAccess
    this.needShow = false
  }

  public custom(
    functional: boolean,
    analytics: boolean,
    perfomance: boolean
  ): void {
    const cookiesAccess = this.generate()
    cookiesAccess.functional = functional
    cookiesAccess.analytics = analytics
    cookiesAccess.perfomance = perfomance
    this.cache = cookiesAccess
    this.needShow = false
  }
}

export const cookiesAccess = new CookiesAccessService()
