import { api } from '@/src/shared/lib/api'

export async function paymentSolidGateRecurringPost<ApiResponse>(
  productId: string,
  cardId: string
) {
  const url = api.appURL('payment/solid_gate/recurring')
  const formData = {
    product_id: productId,
    card_id: cardId,
    context: {
      text: 'view1',
    },
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
