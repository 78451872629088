// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x0wd[data-v-0945db8e]{padding:0 16px;color:#0a0a0a;background-color:#fff}.x0wd[data-v-0945db8e]:active{background-color:#e5e5ea}.x0wd[disabled][data-v-0945db8e]{background-color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "x0wd"
};
module.exports = ___CSS_LOADER_EXPORT___;
