import { api } from '@/src/shared/lib/api'

export async function userFavoritesRequestPut<ApiResponse>(userId: number) {
  const url = api.appURL('user/favorites-request')
  const formData = {
    userId,
  }
  const response = await api.put<ApiResponse>(url, formData, {})
  return api.parseRespone<ApiResponse>(response)
}
