// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ot7Y[data-v-48ed375a]{padding:0 16px;color:#0a0a0a;background-color:#fff;box-shadow:0 2px 6px rgba(0,0,0,.2)}.ot7Y[data-v-48ed375a]:hover{box-shadow:none}.ot7Y[data-v-48ed375a]:active{background-color:#e5e5ea}.ot7Y[disabled][data-v-48ed375a]{background-color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "ot7Y"
};
module.exports = ___CSS_LOADER_EXPORT___;
