// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kZAP[data-v-04d75118]{display:flex;flex-direction:column;justify-content:space-between;min-height:calc(var(--vh, 1vh)*100);background:linear-gradient(180deg,rgba(106,29,177,.2),rgba(106,29,177,0)),#fff}.NsIi[data-v-04d75118]{align-items:center;height:52px;background-color:#fff}.IlPk[data-v-04d75118],.NsIi[data-v-04d75118]{display:flex;justify-content:center}@media screen and (min-width:1024px){.kZAP[data-v-04d75118]{min-height:calc(var(--vh, 1vh)*100)}.kZAP .NsIi[data-v-04d75118]{height:auto;margin-top:18px;margin-bottom:18px;background-color:transparent}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "kZAP",
	"page__logo": "NsIi",
	"page__center": "IlPk"
};
module.exports = ___CSS_LOADER_EXPORT___;
