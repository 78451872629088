import type { AxiosRequestConfig } from 'axios'
import { api } from '@/src/shared/lib/api'

export async function streamerStreamScreenshotPost<ApiResponse>(
  file: File | Blob,
  userId: number,
  context: 'random' | 'private',
  config?: AxiosRequestConfig
) {
  const url = api.appURL('streamer/stream_screenshot')
  const formData = new FormData()
  formData.append('file[file]', file)
  if (context === 'random') formData.append('context[callType]', '1')
  if (context === 'private') formData.append('context[callType]', '2')
  formData.append('context[userId]', userId.toString())
  const response = await api.post<ApiResponse>(url, formData, config)
  return api.parseRespone<ApiResponse>(response)
}
