export class BrowserDetect {
  static isSafari(): boolean {
    return (
      navigator.userAgent.toLowerCase().includes('safari') &&
      !navigator.userAgent.toLowerCase().includes('chrome')
    )
  }

  static isChrome(): boolean {
    return (
      navigator.userAgent.toLowerCase().includes('chrome') &&
      !this.isFirefox() &&
      !this.isOpera() &&
      !this.isSafari()
    )
  }

  static isFirefox(): boolean {
    return navigator.userAgent.toLowerCase().includes('firefox')
  }

  static isOpera(): boolean {
    return navigator.userAgent.toLowerCase().includes('opr')
  }

  static isIOS(): boolean {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  }
}
