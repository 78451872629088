import { Plugin, Context } from '@nuxt/types'
import { HotjarService } from './hotjar.service'
import { cookiesAccess } from '@/src/shared/lib/cookiesAccess'

const nuxtPlugin: Plugin = (context: Context, inject: Function) => {
  const hotjar = new HotjarService(context)
  inject('hotjar', hotjar)

  const access = cookiesAccess.canInit('functional')
  if (!access) return

  hotjar.init()
}

export default nuxtPlugin
