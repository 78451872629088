import { api } from '@/src/shared/lib/api'

export async function userFavoritesRequestGet<ApiResponse>(
  filter: 'received' | 'sent' = 'received',
  page: number = 1
) {
  const url = api.appURL('user/favorites-request')
  if (filter) url.searchParams.set('filter', filter)
  if (page) url.searchParams.set('page', page.toString())
  const response = await api.get<ApiResponse>(url, {})
  return api.parseRespone<ApiResponse>(response)
}
