import { api } from '@/src/shared/lib/api'

export async function videoCallPost<ApiResponse>(
  receiverId: number,
  callTag: string = 'default'
) {
  const url = api.appURL('video/call')
  const formData = {
    receiverId,
    callTag,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
