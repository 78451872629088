import { IEventBus } from '../eventBus'
import { EVENT_MODAL, EVENT_OPEN, EVENT_CLOSE } from './modal.vars'
import { IModalExternalService, IModalPayload } from './modal.types'

export class ModalExternalService implements IModalExternalService {
  protected _eventBus: IEventBus
  protected _name: string

  constructor(name: string, eventBus: IEventBus) {
    this._name = name
    this._eventBus = eventBus
  }

  public get name(): string {
    return this._name
  }

  public nameGenerate(action: string): string {
    return this._eventBus.nameGenerate(EVENT_MODAL, this._name, action)
  }

  public open(payload?: IModalPayload): void {
    const emitName = this._eventBus.nameGenerate(
      EVENT_MODAL,
      this._name,
      EVENT_OPEN
    )
    this._eventBus.emit(emitName, payload)
  }

  public close(payload?: IModalPayload): void {
    const emitName = this._eventBus.nameGenerate(
      EVENT_MODAL,
      this._name,
      EVENT_CLOSE
    )
    this._eventBus.emit(emitName, payload)
  }

  public on(callbackOpen: Function, callbackClose: Function): void {
    const emitNameOpen = this._eventBus.nameGenerate(
      EVENT_MODAL,
      this._name,
      EVENT_OPEN
    )
    const emitNameClose = this._eventBus.nameGenerate(
      EVENT_MODAL,
      this._name,
      EVENT_CLOSE
    )
    this._eventBus.on(emitNameOpen, callbackOpen)
    this._eventBus.on(emitNameClose, callbackClose)
  }

  public off(): void {
    const emitNameOpen = this._eventBus.nameGenerate(
      EVENT_MODAL,
      this._name,
      EVENT_OPEN
    )
    const emitNameClose = this._eventBus.nameGenerate(
      EVENT_MODAL,
      this._name,
      EVENT_CLOSE
    )
    this._eventBus.off(emitNameOpen)
    this._eventBus.off(emitNameClose)
  }

  public action(action: string, payload?: any): void {
    const emitName = this._eventBus.nameGenerate(
      EVENT_MODAL,
      this._name,
      action
    )
    this._eventBus.emit(emitName, payload)
  }

  public actionOn(action: string, callback: Function): void {
    const emitName = this._eventBus.nameGenerate(
      EVENT_MODAL,
      this._name,
      action
    )
    this._eventBus.on(emitName, callback)
  }

  public actionOff(action: string, callback: Function): void {
    const emitName = this._eventBus.nameGenerate(
      EVENT_MODAL,
      this._name,
      action
    )
    this._eventBus.off(emitName, callback)
  }

  public actionOne(action: string, callback: Function): void {
    const emitName = this._eventBus.nameGenerate(
      EVENT_MODAL,
      this._name,
      action
    )
    this._eventBus.one(emitName, callback)
  }
}
