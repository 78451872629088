import { api } from '@/src/shared/lib/api'

enum ApiMessagesThreadType {
  'message' = 1,
  // 'gif' = 2,
  'image' = 3,
  // 'link' = 4,
  // 'location' = 5,
  // 'sticker' = 6,
  // 'ice_breaker' = 7,
  // 'video_message' = 8,
  // 'video' = 9,
  // 'mm' = 10,
  'gift' = 11,
  // 'missed_call' = 13,
  // 'system_info' = 14,
  // 'add_to_favorites' = 15,
}

export async function messagesThreadTextPost<ApiResponse>(
  userId: number,
  message: string
) {
  const url = api.appURL('messages/thread')
  const formData = new FormData()
  formData.append('user_id', userId.toString())
  formData.append('text', message)
  formData.append('type', ApiMessagesThreadType.message.toString())
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}

export async function messagesThreadImagePost<ApiResponse>(
  userId: number,
  id: number
) {
  const url = api.appURL('messages/thread')
  const formData = new FormData()
  formData.append('user_id', userId.toString())
  formData.append('type', ApiMessagesThreadType.image.toString())
  formData.append('a', id.toString())
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}

export async function messagesThreadGiftPost<ApiResponse>(
  userId: number,
  id: number
) {
  const url = api.appURL('messages/thread')
  const formData = new FormData()
  formData.append('user_id', userId.toString())
  formData.append('type', ApiMessagesThreadType.gift.toString())
  formData.append('a', id.toString())
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
