import { state } from './geo.store'
import { ipGeoGet } from '@/src/shared/api'
import { CountryISO } from '@/src/shared/types'

type ApiResponse = {
  geo: {
    countryCode: CountryISO
  }
}

export async function geoGet(): Promise<CountryISO | undefined> {
  const response = await ipGeoGet<ApiResponse>()
  if (response.isOk && response.data?.geo?.countryCode) {
    return response.data.geo.countryCode
  }
}

export async function countryGet(): Promise<CountryISO | undefined> {
  if (state.countryCode) return state.countryCode
  state.countryCode = await geoGet()
  return state.countryCode
}

export async function isUSA(): Promise<boolean> {
  return (await countryGet()) === 'US'
}

export async function isBGorGB(): Promise<boolean> {
  const country = await countryGet()
  return country === 'GB' || country === 'BG'
}
