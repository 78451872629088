import { api } from '@/src/shared/lib/api'

export async function appFbdataPost<ApiResponse>(fbp?: string, fbc?: string) {
  const url = api.appURL('app/fbdata')
  const formData = {
    fbp,
    fbc,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
