import { uuid } from '@/src/shared/lib/uuid'
import { cookies } from '@/src/shared/lib/cookies'

const FIELD_NAME = 'deviceId'

export function deviceIdGet(): string {
  let deviceId = cookies.get(FIELD_NAME) || localStorage.getItem(FIELD_NAME)
  if (!deviceId) {
    deviceId = uuid()
  }
  localStorage.setItem(FIELD_NAME, deviceId)
  cookies.set(FIELD_NAME, deviceId, 365 * 3)

  return deviceId
}
