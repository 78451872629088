import { api } from '@/src/shared/lib/api'

export async function registrationByEmailAndPasswordPost<ApiResponse>(
  email: string,
  password: string,
  partnerId?: string,
  confirmMailing?: boolean
) {
  const url = api.authURL('registration')
  const formData = new FormData()
  formData.append('email', email)
  formData.append('pwd', password)
  if (partnerId) formData.append('p', partnerId)
  if (confirmMailing !== undefined)
    formData.append('emailAdsEnabled', confirmMailing.toString())
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}

export async function registrationBySocialPost<ApiResponse>(
  socialId: string,
  type: 2 | 3 | 4 | 5, // 2-facebook 3-google 4-snapchat 5-itunes
  email: string,
  avatar: string,
  name?: string,
  partnerId?: string
) {
  const url = api.authURL('registration')
  const formData = new FormData()
  formData.append('email', email)
  formData.append('social_id', socialId)
  formData.append('type', type.toString())
  formData.append('profile_photos[]', avatar)
  if (name) formData.append('name', name)
  if (partnerId) formData.append('p', partnerId)
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}

// export async function registrationAutoPost<ApiResponse>() {
//   const url = api.authURL('registration')
//   const email = `U${randomString8Leters()}@autoreg.mili`
//   const password = randomString8Leters() + '' + randomString8Leters()
//   const formData = new FormData()
//   formData.append('reg_type', 'auto_upgradable')
//   formData.append('email', email)
//   formData.append('pwd', password)
//   const response = await api.post<ApiResponse>(url, formData)
//   return api.parseRespone<ApiResponse>(response)
// }
