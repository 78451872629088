import { IModalService, IModalPayload } from './modal.types'
import { EVENT_MODAL, EVENT_OPEN, EVENT_CLOSE } from './modal.vars'
import { IEventBus } from '@/src/shared/lib/eventBus'

export class ModalService implements IModalService {
  protected _eventBus: IEventBus

  constructor(eventBus: IEventBus) {
    this._eventBus = eventBus
  }

  public open(name: string, payload?: IModalPayload): void {
    const emitName = this._eventBus.nameGenerate(EVENT_MODAL, name, EVENT_OPEN)
    this._eventBus.emit(emitName, payload)
  }

  public close(name: string, payload?: IModalPayload): void {
    const emitName = this._eventBus.nameGenerate(EVENT_MODAL, name, EVENT_CLOSE)
    this._eventBus.emit(emitName, payload)
  }

  public on(
    name: string,
    callbackOpen: Function,
    callbackClose: Function
  ): void {
    const emitNameOpen = this._eventBus.nameGenerate(
      EVENT_MODAL,
      name,
      EVENT_OPEN
    )
    const emitNameClose = this._eventBus.nameGenerate(
      EVENT_MODAL,
      name,
      EVENT_CLOSE
    )
    this._eventBus.on(emitNameOpen, callbackOpen)
    this._eventBus.on(emitNameClose, callbackClose)
  }

  public off(name: string): void {
    const emitNameOpen = this._eventBus.nameGenerate(
      EVENT_MODAL,
      name,
      EVENT_OPEN
    )
    const emitNameClose = this._eventBus.nameGenerate(
      EVENT_MODAL,
      name,
      EVENT_CLOSE
    )
    this._eventBus.off(emitNameOpen)
    this._eventBus.off(emitNameClose)
  }

  public action(name: string, action: string, payload?: any): void {
    const emitName = this._eventBus.nameGenerate(EVENT_MODAL, name, action)
    this._eventBus.emit(emitName, payload)
  }

  public actionOn(name: string, action: string, callback: Function): void {
    const emitName = this._eventBus.nameGenerate(EVENT_MODAL, name, action)
    this._eventBus.on(emitName, callback)
  }

  public actionOff(name: string, action: string, callback: Function): void {
    const emitName = this._eventBus.nameGenerate(EVENT_MODAL, name, action)
    this._eventBus.off(emitName, callback)
  }
}
