export const ROUTE_NAV = {
  main: '/cab/video-chat/',
  mainName: 'cab-video-chat',
  randomCall: '/cab/random-call/',
  invoiceHistory: '/cab/invoice-history/',
  blocklist: '/cab/blocklist/',
  messages: '/cab/messages/',
  privateCall: '/cab/private-call/',
  topProfiles: '/cab/top-profiles/',
  stories: '/cab/stories/',
  cabProfile: '/cab/profile/',
  cabProfileSettings: '/cab/profile-settings/',
  // terms: '/terms-and-conditions/',
  // policy: '/privacy-policy/',
  purchaseTerms: '/purchase-terms/',
  // subscriptionTerms: '/subscription-terms/',
  emailConfirmed: '/auth/email-confirmed/',
  banned: '/banned/',

  authWelcome: '/auth/welcome/',
  authLoginEmail: '/auth/login-email/',
  authForgotPassword: '/auth/forgot-password/',
  authRegistrationEmail: '/auth/registration-email/',
  authRegistrationName: '/auth/registration-name/',
  authRegistrationGender: '/auth/registration-gender/',
}
