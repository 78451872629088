import { Plugin, Context } from '@nuxt/types'
import { FacebookService } from './facebook.service'
import { cookiesAccess } from '@/src/shared/lib/cookiesAccess'

const nuxtPlugin: Plugin = (context: Context, inject: Function) => {
  const facebookService = new FacebookService(context)
  inject('facebook', facebookService)
  const access = cookiesAccess.canInit('analytics')
  if (!access) return
  facebookService.init()
}

export default nuxtPlugin
