import { api } from '@/src/shared/lib/api'

export async function passwordRecoveryTokenPost<ApiResponse>(
  token: string,
  newPassword: string,
  newPasswordConfirm: string
) {
  const url = api.authURL('password/recovery/' + token)
  const formData = {
    'new-pwd': newPassword,
    'new-pwd-confirm': newPasswordConfirm,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
