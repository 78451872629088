import { Context } from '@nuxt/types'
import {
  IFacebookService,
  FacebookEvent,
  FacebookEventParameters,
  FacebookEventOptions,
  FacebookInitData,
} from './facebook.types'
import { env } from '@/src/shared/config'
import { cookies } from '@/src/shared/lib/cookies'
import { appFbdataPost } from '@/src/shared/api'
import { cookiesAccess } from '@/src/shared/lib/cookiesAccess'

const PARAM_FACEBOOK_FBP = '_fbp'
const PARAM_FACEBOOK_FBC = '_fbc'

export class FacebookService implements IFacebookService {
  protected _context: Context
  protected _isInit: boolean = false
  protected _isLog: boolean

  constructor(context: Context) {
    this._context = context
    this._isLog = env.IS_DEV || env.IS_STAGE
  }

  public init() {
    this._context.$fb.enable()
    this._isInit = true
  }

  public event(
    event: FacebookEvent,
    data: FacebookEventParameters,
    options?: FacebookEventOptions
  ): void {
    const access = cookiesAccess.checkAccess('analytics')
    if (!access) return
    if (this._isLog) console.log('Facebook event: %s', event, data, options)
    // if (env.IS_DEV)
    //   data.test_event_code = this._context.$config.facebook.testCode

    if (options) {
      this._context.$fb.fbq('track', event, data, options)
    } else {
      this._context.$fb.track(event, data)
    }
  }

  // https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching/
  public eventInit(data: FacebookInitData): void {
    if (this._isLog) console.log('Facebook init:', data)
    this._context.$fb.fbq(
      'init',
      this._context.$config.facebook.facebookId,
      data
    )
  }

  public sendFbcFba() {
    appFbdataPost(
      cookies.get(PARAM_FACEBOOK_FBP),
      cookies.get(PARAM_FACEBOOK_FBC)
    )
  }
}
