import { Plugin, Context } from '@nuxt/types'
import { deviceIdGet } from '@/src/shared/lib/deviceId'

const sentryPlugin: Plugin = (context: Context) => {
  // Device ID
  const deviceId = deviceIdGet()
  if (context.$sentry) {
    context.$sentry.setTag('deviceId', deviceId)
  }
}

export default sentryPlugin
