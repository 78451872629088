import { Context } from '@nuxt/types'
import Hotjar from '@hotjar/browser'
import { IHotjar, HotjarUserPropsSet } from './hotjar.types'
import { deviceIdGet } from '@/src/shared/lib/deviceId'

export class HotjarService implements IHotjar {
  protected _context: Context
  protected _isInit: boolean = false
  public userId: string | null = null

  constructor(context: Context) {
    this._context = context
  }

  public init(): void {
    Hotjar.init(
      this._context.$config.hotjar.id,
      this._context.$config.hotjar.version
    )
    this._context?.app?.router?.afterEach((to) => {
      Hotjar.stateChange(to.fullPath)
    })

    const deviceId = deviceIdGet()
    Hotjar.identify(this.userId, {
      deviceId,
    })
    this._isInit = true
  }

  public userIdSet(userId: string): void {
    if (!this._isInit) return
    this.userId = userId
    Hotjar.identify(userId, {})
  }

  public userPropsSet(props: HotjarUserPropsSet): void {
    if (!this._isInit) return
    Hotjar.identify(this.userId, props)
  }

  public event(name: string): void {
    if (!this._isInit) return
    Hotjar.event(name)
  }
}
