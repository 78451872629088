import { api } from '@/src/shared/lib/api'

interface ApiWebTrafficSourcePayload {
  type: string
  partnerId?: string
  source?: string
  sourceId?: string
}

export async function webTrafficSourcePost<
  ApiResponse,
  ApiPayload extends ApiWebTrafficSourcePayload
>(payload: ApiPayload) {
  const url = api.trackURL('web/traffic_source')
  const formData: any = {
    type: payload.type,
  }
  if (payload.partnerId) formData.partner_id = payload.partnerId
  if (payload.source) formData.source = payload.source
  if (payload.sourceId) formData.source_id = payload.sourceId
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
