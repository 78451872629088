/**
 * @public
 * @constant
 * @type {number}
 * @description The base timeout duration in milliseconds for toasts to be displayed.
 */
export const BASE_TOAST_TIMEOUT = 5000

/**
 * @public
 * @constant
 * @type {number}
 * @description The base delay duration in milliseconds between consecutive toasts in the queue.
 */
export const BASE_TOAST_DELAY = 2000
