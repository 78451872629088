import { api } from '@/src/shared/lib/api'

export async function videochatStepPost<ApiResponse>(step: any, user: any) {
  const url = api.appURL('videochat/step')
  const formData = {
    step,
    user,
  }
  const response = await api.post<ApiResponse>(url, formData, {})
  return api.parseRespone<ApiResponse>(response)
}
