import Vue from 'vue'
import {
  ConfigProgrammatic,
  Icon,
  Button,
  Input,
  Field,
  Loading,
  // Navbar,
  Dropdown,
  // Progress,
  Radio,
  // Rate,
  Select,
  // Steps,
  Sidebar,
  Notification,
  Carousel,
  Collapse,
  // Autocomplete,
  // Taginput,
  // Tag,
  Tabs,
  Tooltip,
  // Toast,
  // Table,
  Checkbox,
  Modal,
  // Menu,
  Switch,
} from 'buefy'

Vue.use(Icon)
Vue.use(Button)
Vue.use(Input)
Vue.use(Field)
Vue.use(Loading)
// Vue.use(Navbar)
// Vue.use(Progress)
Vue.use(Radio)
// Vue.use(Rate)
Vue.use(Dropdown)
Vue.use(Select)
// Vue.use(Steps)
Vue.use(Sidebar)
Vue.use(Notification)
Vue.use(Carousel)
Vue.use(Collapse)
// Vue.use(Autocomplete)
// Vue.use(Taginput)
// Vue.use(Tag)
Vue.use(Tabs)
Vue.use(Tooltip)
// Vue.use(Toast)
// Vue.use(Table)
Vue.use(Checkbox)
Vue.use(Modal)
Vue.use(Switch)
// Vue.use(Menu)

ConfigProgrammatic.setOptions({
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
})

// Vue.use(Buefy, {
//   defaultIconComponent: 'font-awesome-icon',
//   defaultIconPack: 'fas',
// })
