import { api } from '@/src/shared/lib/api'

export async function loginPost<ApiResponse>(email: string, password: string) {
  const url = api.authURL('login')
  const formData = new FormData()
  formData.append('email', email)
  formData.append('pwd', password)
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
