// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X6tg[data-v-3e90ec46]{padding:0 16px;color:#0a0a0a;background-color:#fff;border:1px solid #e8ebf3}.X6tg[data-v-3e90ec46]:active{background-color:#e5e5ea}.X6tg[disabled][data-v-3e90ec46]{background-color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comp": "X6tg"
};
module.exports = ___CSS_LOADER_EXPORT___;
