// import { onBeforeUnmount } from '@nuxtjs/composition-api'
import { eventBus } from '@/src/shared/lib/eventBus'

export function emit<P>(eventName: string, payload: P) {
  eventBus.emit(eventName, payload)
}

export function on<P>(eventName: string, callback: (payload: P) => void): void {
  eventBus.on(eventName, callback)
  // onBeforeUnmount(() => eventBus.off(eventName, callback))
}

export function off<P>(
  eventName: string,
  callback: (payload: P) => void
): void {
  eventBus.off(eventName, callback)
}

export function onManual<P>(
  eventName: string,
  callback: (payload: P) => void
): Function {
  eventBus.on(eventName, callback)
  return () => eventBus.off(eventName, callback)
}

export function one<P>(
  eventName: string,
  callback: (payload: P) => void
): void {
  eventBus.one(eventName, callback)
  // onBeforeUnmount(() => eventBus.off(eventName, callback))
}
