import { api } from '@/src/shared/lib/api'

export async function refreshPost<ApiResponse>(
  accessToken: string,
  refreshToken: string
) {
  const url = api.authURL('refresh')
  const formData = new FormData()
  formData.append('access_token', accessToken)
  formData.append('refresh_token', refreshToken)
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
