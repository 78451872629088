import { api } from '@/src/shared/lib/api'

export async function paymentSolidGateRefundPost<ApiResponse>(orderId: number) {
  const url = api.appURL('payment/solid_gate/refund')
  const formData = {
    order_id: orderId,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
