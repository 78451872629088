import { reactive } from '@nuxtjs/composition-api'
import { CountryISO } from '@/src/shared/types'

type GeoState = {
  countryCode: CountryISO | undefined
}

export const state = reactive<GeoState>({
  countryCode: undefined,
})
