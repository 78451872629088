import { api } from '@/src/shared/lib/api'

export async function messagesGet<ApiResponse>(
  timestemp?: number,
  limit?: number
) {
  const url = api.appURL('messages')
  if (timestemp) url.searchParams.set('ts', timestemp.toString())
  if (limit) url.searchParams.set('limit', limit.toString())
  const response = await api.get<ApiResponse>(url, {})
  return api.parseRespone<ApiResponse>(response)
}
