import { AxiosResponse } from 'axios'
import { ApiResponseBase, ApiResponseState } from './types'
import { ApiError } from './api.error'
import { IError } from '@/src/shared/lib/error'

export function parseRespone<R>(
  response: AxiosResponse<R & ApiResponseBase> | IError
): ApiResponseState<R> {
  const state: ApiResponseState<R> = {
    isOk: false,
    isError: false,
    isAuthError: false,
    isInvalid: false,
    response,
    data: undefined,
    error: undefined,
  }

  if (response instanceof ApiError) {
    state.error = response.data.error
    state.isError = true
  } else {
    const responseRaw = response as AxiosResponse<R & ApiResponseBase>
    state.data = responseRaw.data
    switch (responseRaw.status) {
      case 200:
      case 201:
        if (state.data?.error) {
          const error = responseRaw.data.error
          const errorDetails = error?.errorsDetails || error?.errors_details
          if (error) error.errorsDetails = errorDetails
          if (error?.errors_details) delete error.errors_details
          state.error = error
          state.isInvalid = true
        } else {
          state.isOk = true
        }
        break
      case 401:
        state.isAuthError = true
        break
      default:
        state.isError = true
    }
  }

  return Object.freeze(state)
}
