import { api } from '@/src/shared/lib/api'

export async function paymentSubscriptionCancelPost<ApiResponse>(
  subscriptionId: number,
  reasonText: string,
  contactEmail: string
) {
  const url = api.appURL('payment/subscription/' + subscriptionId + '/cancel')
  const formData = {
    reasonText,
    contactEmail,
  }
  const response = await api.post<ApiResponse>(url, formData, {})
  return api.parseRespone<ApiResponse>(response)
}
