import { Plugin, Context } from '@nuxt/types'
import { init, gtag } from './gtag.service'

/**
 * @link https://github.com/nuxt-community/google-gtag-module/blob/master/lib/plugin.js
 */
const nuxtPlugin: Plugin = (_: Context, inject: Function) => {
  init()
  inject('gtag', gtag)
  gtag('js', new Date())
}

export default nuxtPlugin
