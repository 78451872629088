import { api } from '@/src/shared/lib/api'

export async function emailLoginPost<ApiResponse>(token: string) {
  const url = api.authURL('email_login')
  const formData = {
    token,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
