import { api } from '@/src/shared/lib/api'

export async function loginBySocialPost<ApiResponse>(
  socialId: string,
  type: 2 | 3 | 4 | 5, // 2-facebook 3-google 4-snapchat 5-itunes
  email: string
) {
  const url = api.authURL('soclogin')
  const formData = new FormData()
  formData.append('email', email)
  formData.append('social_id', socialId)
  switch (type) {
    case 2:
      formData.append('network', 'facebook')
      break
    case 3:
      formData.append('network', 'google')
      break
    case 4:
      formData.append('network', 'snapchat')
      break
    case 5:
      formData.append('network', 'itunes')
      break
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
