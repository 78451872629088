import { render, staticRenderFns } from "./ModalCloseSilver.vue?vue&type=template&id=6b351e4d&"
import script from "./ModalCloseSilver.vue?vue&type=script&lang=ts&"
export * from "./ModalCloseSilver.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClose: require('/Users/tarletskiy/Documents/projects/liti/fe-monorepo/apps/peachfy-old/components/icon/IconClose.vue').default})
