import { Context } from '@nuxt/types'
import amplitude from 'amplitude-js'
import { IAmplitudeService, AmplitudeUserPropsSet } from './amplitude.types'
import { deviceIdGet } from '@/src/shared/lib/deviceId'

export class AmplitudeService implements IAmplitudeService {
  protected _context: Context
  protected _isInit: boolean = false

  constructor(context: Context) {
    this._context = context
  }

  public init(): void {
    const defaultAmplitude = amplitude.getInstance()
    defaultAmplitude.init(this._context.$config.amplitude.apiKey, undefined, {
      includeReferrer: true,
      includeUtm: true,
    })

    const gmemAmplitude = amplitude.getInstance('gmem_amplitude')

    gmemAmplitude.init(this._context.$config.amplitude.gmemApiKey, undefined, {
      includeReferrer: true,
      includeUtm: true,
    })

    amplitude
      .getInstance()
      .setVersionName(
        `${this._context.$config.projectName}@${this._context.$config.version}`
      )

    amplitude
      .getInstance('gmem_amplitude')
      .setVersionName(
        `${this._context.$config.projectName}@${this._context.$config.version}`
      )

    const deviceId = deviceIdGet()
    if (deviceId) amplitude.getInstance().setDeviceId(deviceId)
    if (deviceId) amplitude.getInstance('gmem_amplitude').setDeviceId(deviceId)

    this._isInit = true
  }

  public userIdSet(userId: string): void {
    if (!this._isInit) return
    amplitude.getInstance().setUserId(userId)
    amplitude.getInstance('gmem_amplitude').setUserId(userId)
  }

  public userPropsSet(props: AmplitudeUserPropsSet): void {
    if (!this._isInit) return
    amplitude.getInstance().setUserProperties(props)
    amplitude.getInstance('gmem_amplitude').setUserProperties(props)
  }

  public event(name: string, payload?: any): void {
    if (!this._isInit) return
    amplitude.getInstance().logEvent(name, payload)
    amplitude.getInstance('gmem_amplitude').logEvent(name, payload)
  }

  public revenueSet(
    price: number,
    productId: string = 'peachfy_web',
    quantity: number = 1
  ): void {
    if (!this._isInit) return
    const clientDefault = amplitude.getInstance()
    const clientGmem = amplitude.getInstance('gmem_amplitude')
    const revenue = new clientDefault.Revenue()
      .setProductId(productId)
      .setQuantity(quantity)
      .setRevenueType('purchase')
      .setPrice(price)
    clientDefault.logRevenueV2(revenue)

    const revenueGmem = new clientGmem.Revenue()
      .setProductId(productId)
      .setQuantity(quantity)
      .setRevenueType('purchase')
      .setPrice(price)
    clientGmem.logRevenueV2(revenueGmem)
  }
}
