import { api } from '@/src/shared/lib/api'

export async function streamerFeedbackPost<ApiResponse>(
  callType: number, // RANDOM = 1; PRIVATE = 2;
  rating: number,
  streamerUserId: number,
  feedbacks: string[],
  callId: string
) {
  const url = api.appURL('streamer/feedback')
  const formData = {
    callType,
    rating,
    streamerUserId,
    feedbacks,
    callId,
  }
  const response = await api.post<ApiResponse>(url, formData, {})
  return api.parseRespone<ApiResponse>(response)
}
