import { Context } from '@nuxt/types'
import {
  GtagGAPurchase,
  GtagGAPurchaseItem,
  GtagGAUserPropsSet,
  IGoogleAnalyticsService,
} from './googleAnalytics.types'
import { gtag, addScript } from './gtag.service'
import { env } from '@/src/shared/config'
import { deviceIdGet } from '@/src/shared/lib/deviceId'

export class GoogleAnalyticsService implements IGoogleAnalyticsService {
  protected _context: Context
  protected _isInit: boolean = false

  constructor(context: Context) {
    this._context = context
  }

  public init() {
    addScript(
      `https://www.googletagmanager.com/gtag/js?id=${this._context.$config.gtag.measurementId}`
    )

    gtag('config', this._context.$config.gtag.measurementId, {
      debug_mode: env.IS_DEV,
      send_page_view: false,
      // optimize_id: this._context.$config.gtag.optimizeId,
    })

    // this._context?.app?.router?.afterEach((to) => {
    //   gtag('config', this._context.$config.gtag.measurementId, {
    //     page_path: to.fullPath,
    //     location_path: window.location.origin + to.fullPath,
    //   })
    // })

    const deviceId = deviceIdGet()
    gtag('set', 'user_properties', {
      deviceId,
    })

    this._isInit = true
  }

  public event(name: string, payload?: any) {
    gtag('event', name, payload)
  }

  public userPropsSet(props: GtagGAUserPropsSet) {
    gtag('set', 'user_properties', props)
  }

  public userIdSet(userId: string) {
    gtag('set', 'user_properties', { userId })
    gtag('set', 'user_properties', { customer_id: userId })
  }

  /**
   * @link https://developers.google.com/gtagjs/reference/ga4-events#purchase
   */
  public gaPurchase(props: GtagGAPurchase, items: GtagGAPurchaseItem[]): void {
    gtag('event', 'purchase', {
      ...props,
      items,
    })
  }
}
