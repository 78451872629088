import { render, staticRenderFns } from "./ButtonRedOld.vue?vue&type=template&id=2f53f8d7&scoped=true&"
import script from "./ButtonRedOld.vue?vue&type=script&lang=ts&"
export * from "./ButtonRedOld.vue?vue&type=script&lang=ts&"
import style0 from "./ButtonRedOld.vue?vue&type=style&index=0&id=2f53f8d7&lang=scss&scoped=true&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "2f53f8d7",
  null
  
)

export default component.exports