import * as ToasterPlugins from './toaster.service'

/**
 * @public
 * @constant
 * @type {ToasterPlugins.ToasterDesktop}
 * @description Instance of the ToasterDesktop class.
 */
export const ToasterDesktop = new ToasterPlugins.ToasterDesktop()

/**
 * @public
 * @constant
 * @type {ToasterPlugins.ToasterMobile}
 * @description Instance of the ToasterMobile class.
 */
export const ToasterMobile = new ToasterPlugins.ToasterMobile()
