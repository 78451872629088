import { render, staticRenderFns } from "./IconGenderFemale.vue?vue&type=template&id=6be5c818&"
import script from "./IconGenderFemale.vue?vue&type=script&lang=ts&"
export * from "./IconGenderFemale.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports