export const EVENT_MODAL = 'modal'
export const EVENT_OPEN = 'open'
export const EVENT_CLOSE = 'close'

export enum ModalEmits {
  'freeCoins' = 'freeCoins',
  'connectionFailed' = 'connectionFailed',
  'support' = 'support',
  'userDeleted' = 'userDeleted',
  'userDeleting' = 'userDeleting',
  'userBlock' = 'userBlock',
  'userLogout' = 'userLogout',
  'mediaNotSupport' = 'mediaNotSupport',
  'mediaDeniedAccess' = 'mediaDeniedAccess',
  'mediaAllowAccess' = 'mediaAllowAccess',
  'buyCoins' = 'buyCoins',
  'gifts' = 'gifts',
  'giftsNotEnoughCoins' = 'giftsNotEnoughCoins',
  'refundThanks' = 'refundThanks',
  'dialogDelete' = 'dialogDelete',
  // 'dialogBlocking' = 'dialogBlocking',
  'dialogReporting' = 'dialogReporting',
  'privateReceiverBusy' = 'privateReceiverBusy',
  'privateNotEnoughCoins' = 'privateNotEnoughCoins',
  'privateIncomeCall' = 'privateIncomeCall',
  'privateOutcomeCall' = 'privateOutcomeCall',
  'webPush' = 'webPush',
  'payment' = 'payment',
  'profileSettings' = 'profileSettings',
}

export enum ModalActionEmits {
  'mediaAllowAccessSubmit' = 'mediaAllowAccessSubmit',
  'buyCoinsPurchaseStarted' = 'buyCoinsPurchaseStarted',
  'buyCoinsPurchaseError' = 'buyCoinsPurchaseError',
  'buyCoinsPurchaseClose' = 'buyCoinsPurchaseClose',
  'buyCoinsPurchaseSuccess' = 'buyCoinsPurchaseSuccess',
  'dialogDeleteConfirm' = 'dialogDeleteConfirm',
  // 'dialogBlockingConfirm' = 'dialogBlockingConfirm',
  'dialogReportingConfirm' = 'dialogReportingConfirm',
  'giftSelected' = 'giftSelected',
  'userBlockSubmit' = 'userBlockSubmit',
}

export enum ModalEntry {
  'none' = 'none',
  'main' = 'main',
  'invoice' = 'invoiceEmpty',
  'messagesgift' = 'messagesGifts',
  'privategift' = 'privateGifts',
  'randomgift' = 'randomGifts',
  'random' = 'randomMainOrSettings',
  'prolong' = 'randomProlong',
  'private' = 'privateCall',
  'privatecall' = 'privateCall',
  'dialogUnlock' = 'dialogUnlock',
}
