import { render, staticRenderFns } from "./IconStories.vue?vue&type=template&id=2497e6d5&"
import script from "./IconStories.vue?vue&type=script&lang=ts&"
export * from "./IconStories.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports