import { Plugin, Context } from '@nuxt/types'
import { modal } from './modal.state'

/**
 * @description A Nuxt plugin that provides the modal singleton for managing
 * modals throughout the application. The plugin injects the modal instance into
 * the Nuxt context.
 * @type {Plugin}
 * @example
 * // In nuxt.config.ts, add the plugin
 * export default {
 *   plugins: ['@/src/shared/plugins/modal/modal.plugin.ts'],
 *   // ...
 * }
 */
const modalPlugin: Plugin = (_: Context, inject: Function) => {
  inject('modal', modal)
}

export default modalPlugin
