import { api } from '@/src/shared/lib/api'

export async function preRegistrationPost<ApiResponse>(
  gender: number,
  partnerId?: number
) {
  const url = api.authURL('pre_registration')
  const formData = {
    gender,
    partnerId,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
