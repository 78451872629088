import { api } from '@/src/shared/lib/api'

export async function preRegistrationFinishByGooglePost<ApiResponse>(
  email: string,
  socialId: string
) {
  const url = api.authURL('pre_registration/finish_by_google')
  const formData = {
    email,
    socialId,
  }
  const response = await api.post<ApiResponse>(url, formData)
  return api.parseRespone<ApiResponse>(response)
}
